import { render, staticRenderFns } from "./OrderCommentList.vue?vue&type=template&id=2dbdcd91&scoped=true&"
import script from "./OrderCommentList.vue?vue&type=script&lang=js&"
export * from "./OrderCommentList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dbdcd91",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\Git\\booking-fe\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2dbdcd91')) {
      api.createRecord('2dbdcd91', component.options)
    } else {
      api.reload('2dbdcd91', component.options)
    }
    module.hot.accept("./OrderCommentList.vue?vue&type=template&id=2dbdcd91&scoped=true&", function () {
      api.rerender('2dbdcd91', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/App/VPL/MyOrder/OrderDetail/OrderCommentList.vue"
export default component.exports