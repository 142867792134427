<template>
  <div>
    <v-card
      min-height="400"
      :ripple="false"
      width="100%"
      rounded="lg"
      class="pa-6"
    >
      <v-row no-gutters>
        <p class="text-left text-h6 font-weight-medium">
          <span class="d-flex align-center">
            <v-icon
              color="primary"
              left
              >mdi-graph-outline</v-icon
            >
            <span class="primary--text">DANH SÁCH SHIPMENT</span>
          </span>
        </p>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!canUpdateOrder"
          @click="dialog.orderShipmentCreate = true"
        >
          <v-icon left>mdi-plus</v-icon>
          Tạo shipment
        </v-btn>
      </v-row>
      <div class="">
        <v-card
          class="mt-6"
          elevation="0"
          rounded="xl"
        >
          <v-card-text>
            <v-row no-gutters>
              <v-col
                cols="4"
                class="font-weight-bold"
              >
                Thông tin lái xe
              </v-col>

              <v-col
                cols="2"
                class="font-weight-bold text-center"
              >
                Ngày giờ lấy hàng
              </v-col>
              <v-col
                cols="3"
                class="font-weight-bold text-center"
              >
                Trạng thái
              </v-col>
              <v-col
                cols="2"
                class="font-weight-bold"
              >
                Mã Check in/out
              </v-col>
              <v-col
                cols="1"
                class="font-weight-bold text-center"
              >
                Thao tác
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          class="mt-2"
          color="background"
          elevation="0"
          rounded="xl"
          :ripple="false"
          v-for="item in orderShipmentList"
          :key="item.id"
        >
          <v-card-text>
            <v-row
              no-gutters
              class="align-center"
            >
              <v-col
                cols="4"
                class="table-cell"
              >
                <ul>
                  <li>
                    <b>Tên</b> <span>: {{ item.driverName }}</span>
                  </li>
                  <li>
                    <b>Điện thoại</b> <span>: {{ item.driverPhone }}</span>
                  </li>
                  <li>
                    <b>CMND</b>
                    <span>: {{ item.driverIdentificationNumber }}</span>
                  </li>

                  <li>
                    <b>Phương tiện</b><span>: {{ item.driverVehicle }}</span>
                  </li>
                  <li>
                    <b>Container</b>
                    <span>: {{ item.containerName }}</span>
                  </li>
                  <li>
                    <b>Giấy tờ </b
                    ><a @click="onViewDriverVehicheRegistration(item)">Xem</a>
                  </li>
                </ul>
              </v-col>

              <v-col
                cols="2"
                class="text-center"
              >
                {{ $filters.formatDatetime(item.pickUpDate) }}
              </v-col>
              <v-col
                cols="3"
                class="text-center font-weight-medium"
              >
                {{ formatShipmentStatus(item.status) }}
              </v-col>
              <v-col
                cols="2"
                class=""
              >
                <b
                  v-if="isShowVerificationCode(item.status)"
                  class="primary--text"
                >
                  {{ item.verificationCode }}
                </b>
                <span
                  v-else
                  class="font-italic"
                  >Chưa có</span
                >
              </v-col>
              <v-col
                cols="1"
                class="text-center"
              >
                <v-btn
                  icon
                  color="amber darken-4"
                  :disabled="!canUpdateShipment(item.status)"
                  @click="onShowDialogShipmentUpdate(item)"
                >
                  <v-icon>mdi-circle-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="error"
                  :disabled="!canUpdateShipment(item.status)"
                  @click="onShowDialogShipmentDelete(item)"
                >
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <NoData v-if="!isLoading && orderShipmentList.length === 0" />
        <p
          v-if="orderShipmentList.length > 0"
          class="text-secondary font-italic mt-2 pa-2"
          style="font-size: 12px"
        >
          Mã Check in/out sẽ được cấp sau khi order được duyệt
        </p>
      </div>
    </v-card>

    <v-dialog
      v-model="dialog.orderShipmentCreate"
      max-width="550px"
    >
      <OrderShipmentCreate
        v-if="dialog.orderShipmentCreate"
        @close-dialog="dialog.orderShipmentCreate = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.orderShipmentUpdate"
      max-width="550px"
    >
      <OrderShipmentUpdate
        v-if="dialog.orderShipmentUpdate"
        :item="selectedShipment"
        @close-dialog="dialog.orderShipmentUpdate = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.orderShipmentDelete"
      max-width="550px"
    >
      <OrderShipmentDelete
        v-if="dialog.orderShipmentDelete"
        :item="selectedShipment"
        @close-dialog="dialog.orderShipmentDelete = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.viewDriverVehicheRegistration"
      max-width="350px"
    >
      <OrderShipmentListFile
        v-if="dialog.viewDriverVehicheRegistration"
        :item="selectedShipment"
        @close-dialog="dialog.viewDriverVehicheRegistration = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import NoData from '@/components/shared/local/base/NoData.vue';
import { OrderShipmentStatus, OrderStatus } from '@/constants/status.constant';
import { mapActions, mapState } from 'vuex';
import OrderShipmentCreate from './OrderShipmentCreate.vue';
import OrderShipmentDelete from './OrderShipmentDelete.vue';
import OrderShipmentListFile from './OrderShipmentListFile.vue';
import OrderShipmentUpdate from './OrderShipmentUpdate.vue';

export default {
  components: {
    OrderShipmentCreate,
    OrderShipmentDelete,
    OrderShipmentListFile,
    OrderShipmentUpdate,
    NoData,
  },
  data: () => ({
    isLoading: false,
    dialog: {
      orderShipmentCreate: false,
      orderShipmentDelete: false,
      orderShipmentUpdate: false,
      viewDriverVehicheRegistration: false,
    },
    selectedShipment: null,
  }),
  computed: {
    ...mapState('vpl/order', ['order', 'orderShipmentList']),
    ...mapState('vpl/upload', ['uploadedFileList']),
    canUpdateOrder() {
      if (
        this.order.status === OrderStatus.DRAFT ||
        this.order.status === OrderStatus.PENDING ||
        this.order.status === OrderStatus.APPROVED
      ) {
        return true;
      }
      return false;
    },
  },
  async created() {
    await this.fetchOrderShipmentList({ orderId: this.$route.params.id });
  },
  destroyed() {},
  methods: {
    ...mapActions('vpl/order', [
      'fetchOrderShipmentList',
      'deleteOrderShipment',
    ]),
    formatShipmentStatus(value) {
      if (value === OrderShipmentStatus.DRAFT) {
        return 'Khởi tạo';
      }
      if (value === OrderShipmentStatus.PENDING) {
        return 'Chờ duyệt';
      }
      if (value === OrderShipmentStatus.APPROVED) {
        return 'Đã duyệt';
      }
      if (value === OrderShipmentStatus.CHECKED_IN) {
        return 'Đã Check In';
      }
      if (value === OrderShipmentStatus.ENTERED_WAREHOUSE) {
        return 'Đã vào kho';
      }
      if (value === OrderShipmentStatus.CHECKED_OUT) {
        return 'Đã Check Out';
      }
    },
    isShowVerificationCode(status) {
      if (
        status === OrderShipmentStatus.DRAFT ||
        status === OrderShipmentStatus.PENDING
      ) {
        return false;
      }
      return true;
    },
    canUpdateShipment(status) {
      if (
        status === OrderShipmentStatus.DRAFT ||
        status === OrderShipmentStatus.PENDING
      ) {
        return true;
      }
      return false;
    },
    onShowDialogShipmentDelete(item) {
      this.selectedShipment = item;
      this.dialog.orderShipmentDelete = true;
    },
    onShowDialogShipmentUpdate(item) {
      this.selectedShipment = item;
      this.dialog.orderShipmentUpdate = true;
    },
    onViewDriverVehicheRegistration(item) {
      this.selectedShipment = item;
      this.dialog.viewDriverVehicheRegistration = true;
    },
  },
};
</script>

<style scoped lang="scss">
.table-cell {
  > {
    ul {
      list-style: none;
      display: table;
      width: 100%;
      table-layout: fixed;
      padding-left: 12px;
    }

    li {
      display: table-row;
    }

    b {
      display: table-cell;
      width: 110px;
    }

    span {
      display: table-cell;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
