<template>
  <v-card
    :ripple="false"
    width="100%"
    height="100%"
    rounded="lg"
    class="pa-6"
  >
    <p class="text-left text-h6 font-weight-regula font-weight-mediumr">
      <span class="d-flex align-center">
        <v-icon
          color="primary"
          left
          >mdi-comment-processing-outline</v-icon
        >
        <span class="primary--text uppercase">{{ $t('title-comment') }}</span>
      </span>
    </p>
    <div class="">
      <v-slide-y-transition
        group
        leave-absolute
        tag="p"
      >
        <CommentItem
          v-for="item in orderCommentList"
          :key="item.id"
          :avatar="item.user.avatar"
          :name="item.user.name"
          :time="item.createdAt"
          :content="item.content"
          :files="item.files"
        />
      </v-slide-y-transition>
      <CommentTextarea
        v-model="comment"
        :avatar="myInfo.avatarLink"
        :loading="isSendingComment"
        @send="onSendComment"
      />
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ImagePicker from '@/components/shared/local/base/ImagePicker';
import CommentItem from '@/components/shared/local/base/CommentItem.vue';
import CommentTextarea from '@/components/shared/local/base/CommentTextarea.vue';
export default {
  components: { ImagePicker, CommentItem, CommentTextarea },
  data: () => ({
    comment: {
      content: '',
      files: [],
    },
    isLoadingComments: false,
    isSendingComment: false,
    isShiftPressed: false,
  }),
  computed: {
    ...mapState('user', ['myInfo']),
    ...mapState('vpl/order', ['order', 'orderCommentList']),
    ...mapState('vpl/upload', ['order', 'uploadedFileList']),
  },
  async created() {
    this.initalize();
  },
  methods: {
    ...mapMutations('vpl/upload', { RESET_UPLOAD: 'RESET' }),
    ...mapActions('vpl/order', ['fetchOrderCommentList', 'createOrderComment']),
    ...mapActions('vpl/upload', ['uploadFile']),
    async initalize() {
      try {
        this.isLoadingComments = true;
        await this.fetchOrderCommentList({ id: this.$route.params.id });
      } finally {
        this.isLoadingComments = false;
      }
    },
    async onSendComment() {
      try {
        this.isSendingComment = true;
        const formDataList = [];
        this.comment.files.forEach((file) => {
          const formData = new FormData();
          formData.append('file', file);
          formDataList.push(formData);
        });
        await this.uploadFile({ formDataList });
        await this.createOrderComment({
          id: this.$route.params.id,
          content: this.comment.content,
          files: this.uploadedFileList.map(({ fileName }) => fileName),
        });
        await this.initalize();
        this.RESET_UPLOAD();
        this.comment = {
          content: '',
          files: [],
        };
      } finally {
        this.isSendingComment = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
